import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import AulaLocalShowButton from '@/components/AulaLocalShowButton.vue';
import { cursoStore, turmaStore, companyConfiguracaoStore, userActionStore, mainStore } from '@/store';
let TurmaList = class TurmaList extends Vue {
    constructor() {
        super(...arguments);
        this.turmasAtHomeData = [];
        this.cursoSelecionado = null;
        this.idiomaSelecionado = null;
        this.loading = false;
        this.permiteVisualizarCompartilharTurmasAtHome = false;
        this.headers = [
            {
                show: true,
                text: 'Escola',
                value: 'nome_fantasia',
            },
            {
                show: true,
                table: 'livro',
                text: 'Livro',
                type: '',
                value: 'livro',
                field: 'nome',
            },
            {
                show: true,
                table: 'livro',
                text: 'Edição',
                type: '',
                value: 'livro_edicao',
                field: 'edicao',
            },
            {
                show: true,
                table: 'turma',
                text: 'Modalidade',
                type: '',
                value: 'modalidade_id',
            },
            {
                show: true,
                text: 'Alunos/Vagas',
                type: '',
                value: 'numero_aluno',
                table: 'tmp',
            },
            {
                show: true,
                table: 'turma',
                text: 'Início da turma',
                type: '',
                value: 'previsao_inicio',
            },
            {
                show: true,
                table: 'turma',
                text: 'Previsão de fim',
                type: '',
                value: 'previsao_termino',
            },
            {
                show: true,
                table: 'turma',
                text: 'Horários',
                type: '',
                value: 'cronograma_horario',
            },
            {
                show: true,
                text: 'Último conteúdo',
                type: '',
                value: 'ultima_atividade',
                table: 'tmp',
            },
            {
                show: true,
                text: 'Status da turma',
                type: '',
                value: 'status_id',
            },
            {
                show: true,
                text: 'Presenças na ficha de frequência',
                type: '',
                value: 'status_ficha_frequencia',
                table: 'tmp',
            },
            {
                show: true,
                text: 'Local aula',
                value: 'local_aula_id'
            },
        ];
    }
    get cursoOptions() {
        return cursoStore.cursos;
    }
    get turmasAtHome() {
        return this.turmasAtHomeData;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get userActionsPermiteCompartilharTurmasAtHome() {
        return userActionStore.permiteCompartilhamentoTurmasAtHome;
    }
    get companyConfiguracao() {
        return companyConfiguracaoStore.companyConfiguracoes;
    }
    async handleBuscarTurmaAtHome() {
        this.loading = true;
        this.turmasAtHomeData = await turmaStore.getAllTurmasAtHome({ curso_id: this.cursoSelecionado, idioma: this.idiomaSelecionado });
        this.loading = false;
    }
    async handleChangeConfiguracao() {
        await companyConfiguracaoStore.updateCompanyConfiguracoesPermiteVisualizarCompartilharTumasAtHome({ permiteVisualizarCompartilharTurmasAtHome: this.permiteVisualizarCompartilharTurmasAtHome });
    }
    async mounted() {
        await companyConfiguracaoStore.getCompanyConfiguracoes();
        // @ts-ignore
        this.permiteVisualizarCompartilharTurmasAtHome = this.companyConfiguracao.permite_visualizar_compartilhar_turmas_at_home;
    }
};
TurmaList = __decorate([
    Component({
        components: {
            ImprimirTabelaComponent,
            OpenNewTabComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            AulaLocalShowButton
        },
    })
], TurmaList);
export default TurmaList;
