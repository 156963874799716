var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{class:_vm.isMobile ? '' : 'ma-1 pa-3'},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Lista de turmas online")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 'auto'}},[_c('v-autocomplete',{attrs:{"items":_vm.cursoOptions,"label":"Curso","item-value":"id","item-text":"nome","clearable":"","hide-details":""},model:{value:(_vm.cursoSelecionado),callback:function ($$v) {_vm.cursoSelecionado=$$v},expression:"cursoSelecionado"}})],1),_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 'auto'}},[_c('v-autocomplete',{attrs:{"items":['Alemão', 'Espanhol', 'Francês', 'Inglês', 'Italiano'],"label":"Idiomas","clearable":"","hide-details":""},model:{value:(_vm.idiomaSelecionado),callback:function ($$v) {_vm.idiomaSelecionado=$$v},expression:"idiomaSelecionado"}})],1),_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 'auto'}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.handleBuscarTurmaAtHome()}}},[_vm._v(" Buscar ")])],1),_c('v-spacer'),(_vm.userActionsPermiteCompartilharTurmasAtHome)?_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 'auto'}},[_c('v-checkbox',{attrs:{"label":"Visualizar e compartilhar turmas digitais"},on:{"click":function($event){return _vm.handleChangeConfiguracao()}},model:{value:(_vm.permiteVisualizarCompartilharTurmasAtHome),callback:function ($$v) {_vm.permiteVisualizarCompartilharTurmasAtHome=$$v},expression:"permiteVisualizarCompartilharTurmasAtHome"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.turmasAtHome,"footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.modalidade_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.cursoModalidadeOptions()[item.modalidade_id - 1].nome)+" ")]}},{key:"item.previsao_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.previsao_inicio))+" ")]}},{key:"item.previsao_termino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.previsao_termino))+" ")]}},{key:"item.numero_aluno",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero_aluno)+"/"+_vm._s(item.sala_capacidade)+" ")]}},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm._.find(_vm.turmaStatusOptions(), function (i) { return i.id == item.status_id; }).color}},[_vm._v(" "+_vm._s(_vm._.find(_vm.turmaStatusOptions(), function (i) { return i.id == item.status_id; }).name))])]}},{key:"item.cronograma_horario",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-row',[_c('v-col',[[_vm._v(" "+_vm._s(item.cronograma_horario[0].dia_semana)+" "),_c('v-chip-group',{attrs:{"row":""}},_vm._l((item.cronograma_horario),function(aula,idx){return _c('v-chip',{key:idx,attrs:{"x-small":""}},[_vm._v(_vm._s(aula.horario))])}),1)]],2)],1)]}},{key:"item.ultima_atividade",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.ultima_atividade_data))+" "),(item.ultima_atividade)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.ultima_atividade)+" ")])]}},{key:"item.status_ficha_frequencia",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{attrs:{"small":"","color":value == 'Atualizadas' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(value)+" ")]):_c('span')]}},{key:"item.local_aula_id",fn:function(ref){
var item = ref.item;
return [_c('AulaLocalShowButton',{staticClass:"ml-2",attrs:{"propEscolaId":_vm.userProfile.company.escola_id,"propAulaLocal":item.local_aula_id}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }